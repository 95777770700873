












































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { JobSearchAgentType } from '@/store/search/types'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import SearchAgent from '@/components/forms/SearchAgent.vue'
import { Action, State } from 'vuex-class'
import Enums from '@/common/Enums'
import { SAVE_JOB_SEARCH_AGENT } from '@/store/search/actions'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { GET_JOB_TYPES } from '@/store/jobAd/actions'

@Component({
  components: { SearchAgent, BaseForm }
})
/*
This component is used on the JobSearchAgent page and uses the general SearchAgent component inside.
Most props are propagated to the SearchAgent component.
@group SEARCH AGENT
 */
export default class JobSearchAgent extends Vue {
  $refs!: {
    searchAgent: SearchAgent;
  }

  @State('currentScreenWidth') screenWidth: number

  @Action(GET_JOB_TYPES)
  public getJobTypes: () => Promise<string[]>

  @Action(SAVE_JOB_SEARCH_AGENT)
  public saveJobAgent: (agent: JobSearchAgentType) => Promise<string>

  // unique identifier
  @Prop()
  id: string

  // the search data for the job search agent
  @Prop({ required: false })
  searchData: JobSearchAgentType

  // if a delete button is shown
  @Prop({ default: false })
  showDelete: boolean

  // set true for use in a modal
  @Prop({ required: true })
  useInModal: boolean

  // edit mode for the search agent
  @Prop({ default: false })
  editMode: boolean

  @Watch('searchData', { immediate: true })
  onPropsChange (newProps: JobSearchAgentType): void {
    if (newProps) {
      this.internalModel = newProps
      this.internalModel.endDate = null
      this.internalModel.jobType = newProps.jobType === null ? undefined : newProps.jobType
      this.internalModel.workingHours = newProps.workingHours === null ? undefined : newProps.workingHours
      for (let i = 0; i < (this.internalModel.locations ? this.internalModel.locations.length : 1); i++) {
        this.locationsModel['location-' + (i + 1)] = this.internalModel.locations ? this.internalModel.locations[i] : ''
      }
      this.locationsCount = this.internalModel.locations ? Math.max(this.internalModel.locations.length, 1) : 0
    }
  }

  get hasSaveDeleteRight (): boolean {
    return hasRight(Rights.JOB_AD_READ)
  }

  get fields (): Form[] {
    return [
      {
        id: 'jobType',
        key: 'jobType',
        type: InputTypes.SELECT,
        required: false,
        label: this.$t('job-ad.description.type').toString(),
        options: this.jobTypeOptions,
        autofocus: true,
        md: this.useInModal && this.screenWidth < 1230 ? 12 : 6
      },
      {
        id: 'workingHours',
        key: 'workingHours',
        type: InputTypes.SELECT,
        required: false,
        label: this.$t('job-ad.description.working-hours').toString(),
        options: this.workingHours,
        md: this.useInModal && this.screenWidth < 1230 ? 12 : 6
      }
    ]
  }

  get locationFields (): Array<Form> {
    const fields: Form[] = []
    const modelEntries = Object.entries(this.locationsModel)
    for (let i = 0; i < this.locationsCount; i++) {
      fields.push({
        id: modelEntries[i][0],
        key: modelEntries[i][0],
        type: InputTypes.TEXT,
        label: i === 0 ? this.$i18n.t('personal-information.city').toString() : this.$i18n.t('search-agent.additional-location').toString(),
        disabled: !this.hasSaveDeleteRight,
        edit: this.hasSaveDeleteRight,
        deletable: i > 0 && this.hasSaveDeleteRight,
        required: false,
        md: this.useInModal && this.screenWidth < 1230 ? 12 : 6
      })
      fields.push({
        id: 'dummy-' + this.locationsCount,
        key: 'dummy-' + this.locationsCount,
        type: InputTypes.DUMMY
      })
    }
    return fields
  }

  get workingHours (): KeyValue[] {
    const workingHours = Enums.WORKING_HOURS.map(item => {
      return {
        value: item,
        text: this.$t('job-ad.description.WORKING_HOURS.' + item).toString()
      } as KeyValue
    })

    workingHours.push({ value: undefined, text: this.$t('search-agent.everything') } as KeyValue)
    return workingHours
  }

  get jobTypeOptions (): KeyValue[] {
    const jobTypes = this.jobTypes.map(item => {
      return {
        value: item,
        text: this.$t('job-ad.description.job-types.' + item).toString()
      } as KeyValue
    })
    jobTypes.push({ value: undefined, text: this.$t('search-agent.everything') } as KeyValue)

    return jobTypes
  }

  private jobTypes: string[] = []
  private locationsCount = 1
  private keyCounter = 0

  get key (): string {
    return this.id + this.keyCounter
  }

  private internalModel: JobSearchAgentType = {
    searchAgentId: '',
    interval: '',
    startDate: new Date(),
    endDate: null,
    searchName: '',
    jobType: undefined,
    workingHours: undefined,
    locations: []
  }

  private locationsModel: any = {
    'location-1': ''
  }

  /**
   * Add item to locationModel and
   * update locationCount in order to display the correct amount of input fields
   */
  public addLocation (): void {
    this.locationsCount++
    this.locationsModel['location-' + this.locationsCount] = ''
  }

  /**
   * Remove one item from locationModel
   * Therefore reorder all other fields and dismiss the entry to be removed
   * Update locationModel and propagate changes to parent component
   **/
  public removeLocation (id: string): void {
    if (this.locationsCount > 1) {
      let arrayIndex = 0
      const updatedLocations: any = {}
      Object.entries(this.locationsModel).map(entry => {
        if (entry[0] !== id) {
          updatedLocations['location-' + arrayIndex] = entry[1] as string
          arrayIndex++
        }
      })
      this.locationsModel = updatedLocations
      this.locationsCount--
    }
  }

  saveAgent (): void {
    let failed = true
    let message = ''
    this.internalModel.locations = Object.values(this.locationsModel) as string []
    this.internalModel.locations = this.internalModel.locations.filter(location => {
      return location !== ''
    })
    this.saveJobAgent(this.internalModel).then((id) => {
      message = this.$t('info.save-success', { what: this.$tc('search-agent.search-agent', 1) }).toString()
      failed = false
      this.internalModel.searchAgentId = id
      this.$emit('saved', this.internalModel)
    }).catch(() => {
      message = this.$t('info.save-failed', { what: this.$tc('search-agent.search-agent', 1) }).toString()
      failed = true
    }).finally(() => {
      this.$root.$emit('alert', message, '', failed)
    })
  }

  created (): void {
    this.getJobTypes().then(data => {
      this.jobTypes = data
    })
  }
}
