











































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import InfoBox from '@/components/hszg/InfoBox.vue'
import { GET_JOB_ADS_TO_JOB_FAMILY } from '@/store/student/actions'
import { JobFinderType } from '@/store/kode/types'
import JobSearchAgent from '@/components/forms/JobSearchAgent.vue'
import { DELETE_JOB_SEARCH_AGENT, GET_ALL_JOB_SEARCH_AGENTS } from '@/store/search/actions'
import { Agents, JobSearchAgentType } from '@/store/search/types'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import { SortingInfo } from '@/store/commonFormInput/types'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { SimpleServerResponse } from '@/store/types'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import EventSearchAgent from '@/components/forms/EventSearchAgent.vue'

@Component({
  components: { ResultsPage, JobSearchAgent, InfoBox, ConfirmationModal }
})
export default class JobSearchAgentPage extends Vue {
  $refs!: {
    editModal: EventSearchAgent;
  }

  @Action(GET_JOB_ADS_TO_JOB_FAMILY)
  public getJobsToJobFamily: () => Promise<Array<JobFinderType>>

  @Action(GET_ALL_JOB_SEARCH_AGENTS)
  public getAllJobSearchAgents: () => Promise<Agents>

  @Action(DELETE_JOB_SEARCH_AGENT)
  public deleteJobSearchAgent: (id: string) => Promise<SimpleServerResponse>

  private jobFamily: string | null = null
  private loading = true
  private searchAgents: Array<JobSearchAgentType> = []
  private selectedAgent: JobSearchAgentType = {
    searchAgentId: '',
    interval: '',
    startDate: new Date(),
    endDate: null,
    searchName: ''
  }

  private maxCount: number | null = null
  private modalTitle = ''

  get sortable (): SortingInfo[] {
    return [
      { key: 'startDate', label: this.$t('search-agent.start-date').toString() },
      { key: 'searchName', label: this.$t('search-agent.name').toString() }
    ]
  }

  get hasSaveDeleteRight (): boolean {
    return hasRight(Rights.JOB_AD_READ)
  }

  showConfirmationModal (agent: JobSearchAgentType): void {
    this.selectedAgent = agent
    this.$bvModal.show('confirmation-modal-delete-search-agent')
  }

  confirmDeleteModal (): void {
    let failed = true
    let message = ''
    this.deleteJobSearchAgent(this.selectedAgent.searchAgentId).then(() => {
      message = this.$t('info.delete-success', { what: this.$tc('search-agent.search-agent', 1) }).toString()
      failed = false
      this.updateAgentList(this.selectedAgent, true)
    }).catch(() => {
      message = this.$t('info.delete-failed', { what: this.$tc('search-agent.search-agent', 1) }).toString()
      failed = true
    }).finally(() => {
      this.$root.$emit('alert', message, '', failed)
      this.clearModel()
    })
  }

  clearModel (): void {
    this.selectedAgent = {
      searchAgentId: '',
      interval: '',
      startDate: new Date(),
      endDate: null,
      searchName: ''
    }
    this.modalTitle = this.$t('search-agent.create-search-agent').toString()
  }

  prepareForEdit (agent: JobSearchAgentType) {
    this.selectedAgent = {
      searchAgentId: agent.searchAgentId,
      searchName: agent.searchName,
      startDate: agent.startDate,
      endDate: agent.endDate,
      interval: agent.interval,
      workingHours: agent.workingHours,
      locations: agent.locations,
      jobType: agent.jobType
    }
    this.modalTitle = this.$t('search-agent.edit-search-agent').toString()
    this.$bvModal.show('new-agent-modal')
  }

  saveAgent (): void {
    this.$refs.editModal.$refs.searchAgent.$refs.submitButton.click()
  }

  updateAgentList (agent: JobSearchAgentType, isDelete: boolean): void {
    const index = this.searchAgents.findIndex(item => {
      return item.searchAgentId === agent.searchAgentId
    })

    if (isDelete && index !== -1) {
      this.searchAgents.splice(index, 1)
    }

    if (!isDelete) {
      if (index === -1) {
        this.searchAgents.splice(0, 0, agent)
      } else {
        this.$set(this.searchAgents, index, { ...agent })
      }
    }

    this.$bvModal.hide('new-agent-modal')
  }

  created (): void {
    this.loading = true
    this.getJobsToJobFamily().then(data => {
      this.jobFamily = data.length > 0 ? data[0].competenceProfileType : null
      this.loadAgents()
    }).catch(error => {
      if (error.message !== 'no job family selected') {
        this.$root.$emit('alert',
          this.$t('warning.error!').toString(),
          this.$tc('warning.could-not-load', 1, { what: this.$tc('jobs-to-job-family.professional-goal', 1) }).toString(),
          true
        )
      }
    }).finally(() => {
      this.loading = false
    })
  }

  loadAgents (): void {
    this.getAllJobSearchAgents().then(data => {
      this.searchAgents = data.searchAgentList as JobSearchAgentType[]
      this.maxCount = data.maxNumberJobAgents
    }).catch(() => {
      this.$root.$emit('alert',
        this.$t('warning.error!').toString(),
        this.$tc('warning.could-not-load', 1, { what: this.$t('search-agent.job-search-agent') }).toString(),
        true
      )
    })
  }
}
